import { maxPetAgeNumberOfYears } from "../settings/pet";
import { getIsoDate } from "./date";

export const getMonthOptions = (): { value: string; label: string }[] => {
  return Array.from({ length: 12 }, (_, index) => ({
    value: index.toString(),
    label: `common.month.${index}.label`,
  }));
};

export const getYearsAvailable = (): string[] => {
  const currentYear = new Date().getFullYear();

  return Array.from({ length: maxPetAgeNumberOfYears + 1 }, (_, index) =>
    (currentYear - index).toString()
  );
};

export const calcPetBirthDate = (month: string, year: string): string => {
  const petBirthDate: Date = new Date();

  petBirthDate.setFullYear(parseInt(year, 10), parseInt(month, 10), 1);

  return getIsoDate(petBirthDate);
};
