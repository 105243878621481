import cn from "classnames";
import { Field, Form, FormikHelpers, FormikProps } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Fragment, ReactElement, useState } from "react";

import PageStepForm from "../../../../../../organisms/page-step-form/PageStepForm";
import { calcPetBirthDate, getMonthOptions, getYearsAvailable } from "../../../../../../utils/age";
import { Events, track } from "../../../../../../utils/analytics";
import { rollbar } from "../../../../../../utils/rollbar";
import { useSetLead } from "../../../../application/lead-use-cases";
import { Lead } from "../../../../domain/lead";
import * as styles from "./PartialBirthdate.module.scss";

interface PetBirthdateFormValues {
  month: string | null;
  year: string | null;
}

interface Image {
  default: string;
}

const petBirthdateSVG: Image = require("../../../images/birthday.svg") as Image;

const petBirthdateSVGSrc: string = petBirthdateSVG.default;

const PartialBirthdate = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const setLead = useSetLead();
  const nextUrl = `/${i18n.language}/onboarding/breed/`;
  const months = getMonthOptions();
  const years = getYearsAvailable();
  const [monthSelected, setMonthSelected] = useState<string | null>(null);
  const [yearSelected, setYearSelected] = useState<string | null>(null);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const PetBirthdateFormData = {
    initialValues: {
      month: null,
      year: null,
    },
    handleSubmit: (
      values: PetBirthdateFormValues,
      { setSubmitting }: FormikHelpers<PetBirthdateFormValues>
    ): void => {
      if (!values.month || !values.year) {
        setSubmitting(false);
        values.year && setYearSelected(values.year);
        values.month && setMonthSelected(values.month);

        return;
      }

      setLead({ petBirthDate: new Date(calcPetBirthDate(values.month, values.year)) })
        .then((updatedLead: Lead | undefined): void => {
          if (!updatedLead) {
            setSubmitting(false);

            return;
          }

          const selectedMonth = months.find(
            (month) => month.value === values.month?.toString()
          )?.label;

          setTimeout(() => navigate(nextUrl), 500);

          if (!selectedMonth) {
            return;
          }

          track(Events.FORM_ANSWERED, {
            question: "Pet birthdate",
            answer: `${t(selectedMonth, { lng: "en" })} ${values.year ? values.year : ""}`,
            isExactPetBirthdate: false,
          });
        })
        .catch(() => {
          rollbar.warn("There was an error setting the lead.");
          setSubmitting(false);
        });
    },
    children: (props: FormikProps<PetBirthdateFormValues>) => {
      const { handleSubmit, isSubmitting } = props;

      return (
        <Form className={cn(styles.petBirthdateForm)} onChange={() => handleSubmit()}>
          <section>
            {months.map((month) => {
              const { value, label } = month;
              const isFutureMonth =
                currentYear === parseInt(yearSelected || "", 10) &&
                parseInt(value, 10) > currentMonth;

              return (
                <Fragment key={value}>
                  <Field
                    type="radio"
                    name="month"
                    value={value}
                    id={value}
                    disabled={isSubmitting || isFutureMonth}
                  />
                  <label htmlFor={value} className={cn({ [styles.labelDisabled]: isFutureMonth })}>
                    {t(label)}
                  </label>
                </Fragment>
              );
            })}
          </section>
          <section>
            {years.map((year) => {
              const selectedMonthIsFuture =
                parseInt(year || "", 10) === currentYear &&
                parseInt(monthSelected || "", 10) > currentMonth;

              return (
                <Fragment key={year}>
                  <Field
                    type="radio"
                    name="year"
                    value={year}
                    id={year}
                    disabled={isSubmitting || selectedMonthIsFuture}
                  />
                  <label
                    htmlFor={year}
                    className={cn({ [styles.labelDisabled]: selectedMonthIsFuture })}
                  >
                    {year}
                  </label>
                </Fragment>
              );
            })}
          </section>
        </Form>
      );
    },
  };

  return (
    <PageStepForm
      title={t("onboarding.partial_birthdate.title")}
      subtitle={t("onboarding.partial_birthdate.subtitle")}
      image={petBirthdateSVGSrc}
      formData={PetBirthdateFormData}
    />
  );
};
export default PartialBirthdate;
