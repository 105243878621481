import { graphql } from "gatsby";

import Birthdate from "../../features/leads-funnel/ui/pages/onboarding/birthdate/Birthdate";
import OnboardingLayout from "../../organisms/lead/OnboardingLayout";

const BirthdatePage = (): JSX.Element => (
  <OnboardingLayout>
    <Birthdate />
  </OnboardingLayout>
);

export default BirthdatePage;

export const query = graphql`
  query BirthdatePageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
