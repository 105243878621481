import { DateFieldState, DateSegment as DateSegmentType } from "@react-stately/datepicker";
import cn from "classnames";
import { ReactElement, useRef } from "react";
import { useDateSegment } from "react-aria";

import * as styles from "./DateSegment.module.scss";

interface DateSegmentProps {
  segment: DateSegmentType;
  state: DateFieldState;
  handleFocus: () => void;
  handleBlur: () => void;
}

const DateSegment = ({
  segment,
  state,
  handleFocus,
  handleBlur,
}: DateSegmentProps): ReactElement => {
  const ref = useRef(null);
  const { segmentProps } = useDateSegment(segment, state, ref);
  const { isPlaceholder, text } = segment;

  return (
    <div
      {...segmentProps}
      onFocus={handleFocus}
      onBlur={handleBlur}
      ref={ref}
      className={cn(styles.segment, { [styles.placeholder]: isPlaceholder })}
    >
      {text}
    </div>
  );
};

export default DateSegment;
